<template>
	<div>
		<scabe class="mx-2 my-3 border-b border-black" :name="me.name" />
		<div class="py-2 border-b border-black w-3/5 mx-2 font-bold">
			<p class="mt-2">Pedidos asignados</p>
		</div>
		<div v-if="PedidoVendedorAsignado != ''" class="mt-3 mx-2">
			<div class="flex items-center justify-center py-2 mx-1 z-0">
				<table class="table-auto">
					<thead>
						<tr class="border-b border-black">
							<th class="p-1">Orden de compra</th>
							<th class="border-r border-l p-1 border-black">
								Fecha de entrega
							</th>
							<th class="p-1">Confirmar pedido</th>
						</tr>
					</thead>
					<tbody>
						<tr
							class="border-b border-black"
							v-for="(pedido, k) in PedidoVendedorAsignado"
							:key="k"
						>
							<td class="py-1 text-center">
								<router-link class="text-blue-500 underline" to="#">{{
									pedido.id.padStart(4, 0)
								}}</router-link>
							</td>
							<td class="py-1 text-center">{{ pedido.fecha_entrega }}</td>
							<td class="py-1 text-center">
								<button class="mx-2 focus:outline-none">
									<svg
										version="1.1"
										id="Capa_1"
										xmlns="http://www.w3.org/2000/svg"
										xmlns:xlink="http://www.w3.org/1999/xlink"
										x="0px"
										y="0px"
										viewBox="0 0 367.805 367.805"
										style="enable-background:new 0 0 367.805 367.805;"
										xml:space="preserve"
										class="h-8 w-8"
									>
										<g>
											<path
												style="fill:#3BB54A;"
												d="M183.903,0.001c101.566,0,183.902,82.336,183.902,183.902s-82.336,183.902-183.902,183.902 S0.001,285.469,0.001,183.903l0,0C-0.288,82.625,81.579,0.29,182.856,0.001C183.205,0,183.554,0,183.903,0.001z"
											/>
											<polygon
												style="fill:#D4E1F4;"
												points="285.78,133.225 155.168,263.837 82.025,191.217 111.805,161.96 155.168,204.801 256.001,103.968"
											/>
										</g>
									</svg>
								</button>
								<button class="mx-2 focus:outline-none">
									<svg
										version="1.1"
										id="Capa_1"
										xmlns="http://www.w3.org/2000/svg"
										xmlns:xlink="http://www.w3.org/1999/xlink"
										x="0px"
										y="0px"
										viewBox="0 0 368.022 368.022"
										style="enable-background:new 0 0 368.022 368.022;"
										xml:space="preserve"
										class="h-8 w-8"
									>
										<g>
											<path
												style="fill:#D7443E;"
												d="M314.101,54.133c71.79,71.847,71.744,188.287-0.103,260.077s-188.287,71.744-260.077-0.103 c-71.75-71.806-71.75-188.167,0-259.974c71.381-71.847,187.49-72.224,259.337-0.843C313.54,53.57,313.821,53.851,314.101,54.133z"
											/>
											<polygon
												style="fill:#D4E1F4;"
												points="275.439,124.663 215.88,184.223 275.439,243.782 243.57,275.651 184.011,216.092 124.452,275.651 92.582,243.782 152.142,184.223 92.582,124.663 124.452,92.794 184.011,152.353 243.57,92.794"
											/>
										</g>
									</svg>
								</button>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
		<div v-else class="my-3 mx-2 font-bold">
			No hay pedidos asignados actualmente
		</div>
	</div>
</template>
<script>
import scabe from "@/components/subCabeceraV.vue";
import gql from "graphql-tag";
export default {
	components: {
		scabe
	},
	data() {
		return {
			me: [],
			Vid: ""
		};
	},
	mounted() {
		this.$store.state.isLoading = true;
		this.$apollo
			.query({
				query: gql`
					query me {
						me {
							name
							id
							vendedores {
								id
								disponible
							}
						}
					}
				`
			})
			.then(data => {
				this.$store.state.isLoading = false;
				this.me = data.data.me;
				this.Vid = data.data.me.vendedores[0].id;
			})
			.catch(error => {
				this.$store.state.isLoading = false;
				console.log(error);
			});
	},
	apollo: {
		PedidoVendedorAsignado: {
			query: gql`
				query PedidoVendedorAsignado($id: ID!) {
					PedidoVendedorAsignado(vendedor_id: $id) {
						id
						estatus
						vendedor_id
						tiempo_estimado
						fecha_entrega
					}
				}
			`,
			variables() {
				return {
					id: this.Vid
				};
			},
			fetchPolicy: "cache-and-network",
			update: data => data.PedidoVendedorAsignado,
			error: (error, vm) => (vm.feedback.errorSearched = true),
			watchLoading(isLoading, countModifier) {
				// isLoading is a boolean
				// countModifier is either 1 or -1
				this.$store.state.isLoading = isLoading;
			}
		}
	}
};
</script>
